.landing-search__select {
  :global(.react-select__input) {
    width: 100%;
    border-radius: 0px;
    input[type='text'] {
      min-width: 100%;
    }
  }
}

.landing-search__select--lg {
  :global(.react-select__control) {
    min-height: 70px;
    font-size: 1rem;
    border-radius: 0px;
  }
}
