@import './react-select';
@import './react-verification-input';
@import url('https://img.certcheck.ukas.com/assets/images/flags/v1.2/flags.css');

// Font: MetronicPro
body {
  font-family: 'MetronicProLight', 'Open Sans', sans-serif;
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.font-size {
  &-xs {
    font-size: 0.75rem;
  }
  &-sm {
    //font-size: 0.875rem;
  }
  &-md {
    font-size: 1rem;
  }
  &-lg {
    font-size: 1.125rem;
  }
  &-xl {
    font-size: 1.25rem;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

// add these in public/index.html - flatpickr/dist
.shortcut-buttons-flatpickr-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;

  padding-top: 1rem;
}

.shortcut-buttons-flatpickr-buttons {
  display: flex;
  gap: 1rem;
}

// isMonthly
.flatpickr-monthSelect-months {
  margin: 10px 1px 3px 1px;
  flex-wrap: wrap;
  width: 300px;
}
